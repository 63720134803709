import "core-js/features/url-search-params";
import { FileManagementViewRouteParameters, SolutionsViewRouteParamaters } from "./RouteParams";

export class Routing {
	public static Routes = {
		HomePage: "/",
		Solutions: "/solutions",
		Administration: "/administration",
		FileManagement: "/fileManagement",
		ChannelManagement: "/channelManagement",
		MyChannels: "/myChannels",
		NewFile: "/newFile",
    	Contacts: "/contacts",
    	Opensource: "/opensource",
		CategoryManagement: "/categoryManagement",
	};

	/**
	 * Create full url with params, e.g. /Home?foo=bar
	 * @param base base of url e.g. /Home
	 * @param params object of params, where object key/value pair corresponds to query params
	 * @returns full url with params
	 */
	private static constructFullUrl(base: string, params?: any) {
		let result: string = base;
		let query: URLSearchParams;

		if (params) {
			query = new URLSearchParams();

			Object.keys(params).forEach((paramKey) => {
				query.append(paramKey, params[paramKey]);
			});

			result = `${result}?${query.toString()}`;
		}

		return result;
	}

	public static getHomeViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.HomePage);
	}

	public static getSolutionsViewUrl(params: SolutionsViewRouteParamaters): string {
		return Routing.constructFullUrl(Routing.Routes.Solutions, params);
	}

	public static getFileManagementUrl(params: FileManagementViewRouteParameters): string {
		return Routing.constructFullUrl(Routing.Routes.FileManagement, params);
	}

	public static getNewFileViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.NewFile);
	}

	public static getContactsViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.Contacts);
  	}

  	public static getOpensourceViewUrl(): string {
    	return Routing.constructFullUrl(Routing.Routes.Opensource);
  	}

  	// As below but passes the node ID from the selected node when called from the Solutions Browser view
	// public static getChannelManagementContextViewUrl(params: ChannelManagementContextViewRouteParameters): string {
   	// 	return Routing.constructFullUrl(Routing.Routes.ChannelManagementContext, params);
  	// }

	public static getChannelManagementViewUrl(): string {
    	return Routing.constructFullUrl(Routing.Routes.ChannelManagement);
  	}

	public static getMyChannelsViewUrl(): string {
    	return Routing.constructFullUrl(Routing.Routes.MyChannels);
  	}

	public static getCategoryManagementViewUrl(): string {
		return Routing.constructFullUrl(Routing.Routes.CategoryManagement);
	}
}
